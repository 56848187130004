import React              from "react";
import {InputType}        from "./model/inputType";
import {Label, LabelItem} from "shared/ui";
import Icon               from "../icon/Icon";
import cn                 from "classnames";
import styles             from "./Input.module.scss";
import {loaderAnimation}  from "../../../app/styles/assets/lotties";
import Lottie             from "lottie-react";


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType?: InputType;
  label?: string;
  icon?: string | React.ReactNode;
  hasLoading?: boolean;
  leftText?: string;
  rightIcon?: React.ReactNode;
  errorMessage?: string;
  subtext?: string | React.ReactNode;
  labels?: LabelItem[];
  hasSpace?: boolean;
  dropDownValue?: string | number;
  onDropDownClick?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  clearable?: boolean;
  children?: React.ReactNode;
  onClearClick?: () => void;
}

interface InputComponent extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLInputElement>> {
  Dropdown: typeof Dropdown;
}

type InputContextType = {
  dropDownValue?: string | number;
  onDropDownClick?: () => void;
  disabled?: boolean;
}

const InputContext = React.createContext<InputContextType | undefined>(undefined);

function useInputContext() {
  const context = React.useContext(InputContext);
  if (!context) {
    throw new Error("useButtonContext must be used within a InputContext");
  }
  return context;
}

const Input = React.forwardRef<HTMLInputElement, Props>(({
  inputType = InputType.DEFAULT,
  hasSpace = true,
  hasLoading = false,
  clearable = false,
  dropDownValue,
  label,
  leftText,
  rightIcon,
  errorMessage,
  labels,
  subtext,
  onChange,
  icon,
  children,
  onClearClick,
  onDropDownClick = () => {
  },
  onClick,
  ...rest
}, ref) => {
  const id = React.useId();

  const handleOnInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    onClick && onClick(event);
  }

  const handleOnClearClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (ref && "current" in ref && ref.current) {
      ref.current.value = "";
    }
    onClearClick && onClearClick();
    onChange && onChange({target: {value: ""}} as React.ChangeEvent<HTMLInputElement>);
  }

  return <InputContext.Provider value={{disabled: rest.disabled, dropDownValue, onDropDownClick}}>
    <div className={cn(styles.wrapper, {[styles.has_space]: hasSpace})}>
      {(!!label || !!labels?.length) && <div className={styles.label_holder}>
        {label && <label htmlFor={id}>{label}</label>}
        {labels && <Label clasNames="mgl-2" labels={labels} />}
      </div>}

      <div
        className={cn(styles.input_holder, {[styles.disabled]: rest.disabled}, {[styles.error]: !!errorMessage}, {[styles.loading]: hasLoading})}>
        {icon && typeof icon === "string" ? <div className={styles.icon_holder}>{icon}</div> : <>{icon}</>}
        {leftText && <span className={styles.left_text}>{leftText}</span>}
        {inputType === InputType.MAP
          ? <div className={cn(styles.map_value, {[styles.placeholder]: !rest.value})} onClick={handleOnInputClick}>
            {!rest.value ? rest.placeholder : rest.value}
          </div>

          : <input
            ref={ref}
            autoComplete="off"
            onContextMenu={(e) => e.preventDefault()}
            className={cn(styles.input, {[styles.error]: !!errorMessage})}
            onChange={onChange}
            onClick={handleOnInputClick}
            id={id}
            {...rest}
          />}
        {clearable && <Icon
          onMouseDown={handleOnClearClick}
          className={styles.clear_icon}
          iconColor="#2B2B2B"
        >
          cancel
        </Icon>}
        {hasLoading && <Lottie
          autoPlay
          animationData={loaderAnimation}
          className={styles.input_loading}
          loop
        />}
        {rightIcon && <>{rightIcon}</>}
        {children}
      </div>

      {!!errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
      {subtext && !errorMessage && <>{typeof subtext === "string" ?
        <span className={styles.subtext}>{subtext}</span> : <>{subtext}</>}</>}
    </div>
  </InputContext.Provider>
});

function Dropdown() {
  const {dropDownValue, onDropDownClick, disabled} = useInputContext();

  const handleDropDownClick = () => {
    if (disabled) return;
    onDropDownClick && onDropDownClick();
  }

  return <div className={styles.dropdown_holder} onClick={handleDropDownClick}>
    {dropDownValue && <span className={styles.dropdown_value}>{dropDownValue}</span>}
    <span className={styles.dropdown_icon}>keyboard_arrow_down</span>
  </div>
}

(Input as InputComponent).Dropdown = Dropdown;

export default Input as InputComponent;