import * as  React                 from "react";
import {useSearchParams}           from "react-router-dom";
import {RootRoutes}                from "../routes";
import {changeLanguage}            from "i18next";
import {ErrorNotification}         from "components/ErrorNotification";
import {useAppDispatch, setLocale} from "shared/model";
import {PhotoSwipeProvider}        from "shared/ui";
import {NoInternetConnection}      from "entities/general";
import styles                      from "./App.module.scss";


function App() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const localeValue = searchParams.get("locale");

  React.useEffect(() => {
    if (localeValue) {
      changeLanguage(localeValue);
      dispatch(setLocale(localeValue));
    }
  }, [dispatch, localeValue]);

  React.useLayoutEffect(() => {
    window.fromNative = (module, action, data) => {
      //@ts-ignore
      window.lastNativeMessage = {module, action, data};
      window.dispatchEvent(new CustomEvent("nativeMessage", {detail: {module, action, data}}));
    };
  }, []);

  return <div className={styles.wrapper}>
    <NoInternetConnection>
      <PhotoSwipeProvider>
        <RootRoutes />
      </PhotoSwipeProvider>
    </NoInternetConnection>
    <ErrorNotification />
  </div>
}

export default App;