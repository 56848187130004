import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {InApp}                                 from "shared/ui";
import {nativeMethod}                          from "shared/lib";
import {useAppDispatch, useAppSelector}        from "shared/model";
import {
  selectGeoInAppIsOpen,
  setGeolocationPermission,
  toggleGeoInApp,
}                                              from "../model/permissionsSlice";


interface Props {
  onGetGeoCoords?: () => void;
  onClose?: () => void;
}

const i18nPrefix = "features.permissions.geolocation."
export default function GeolocationRequest({
  onGetGeoCoords = () => {
  },
  onClose = () => {
  },
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const geoInAppIsOpen = useAppSelector(selectGeoInAppIsOpen);

  const handleCloseInAppClick = () => {
    onClose();
    dispatch(toggleGeoInApp(false));
  }

  const handleRequestGeoPermissionClick = () => {
    dispatch(toggleGeoInApp(false));
    nativeMethod().giveGeolocationPermission().then((res) => {
      if (res.has_permissions) {
        return handleResponseGeoPermission(res.has_permissions);
      }
    }).catch((error) => {
      console.warn(error);
    })
  }

  const handleResponseGeoPermission = (has_permissions: boolean) => {
    dispatch(setGeolocationPermission(has_permissions));
    if (has_permissions === true) {
      return onGetGeoCoords();
    }
  }

  return <InApp
    image="/illustrations/find-a-way-3.svg"
    headerText={t(`${i18nPrefix}text`)}
    headerSubtext={t(`${i18nPrefix}subtext`)}
    open={geoInAppIsOpen}
    onClose={handleCloseInAppClick}
  >
    <div className="inApp_buttons">
      <Button
        buttonColor={NewButtonColor.GRAY}
        buttonType={NewButtonType.WRAPPED}
        text={t(`${i18nPrefix}cancel_button`)}
        onClick={handleCloseInAppClick}
      />

      <Button hasSpace text={t("common.allow")} onClick={handleRequestGeoPermissionClick} />
    </div>
  </InApp>
};