import React            from "react";
import {useTranslation} from "react-i18next";
import {
  generateArray,
  generateMaxYear,
  generateYears,
  getDaysInMonth,
}                       from "./lib/datePickerHelpers";
import {Button}         from "../../v12ui";
import styles           from "./styles.module.scss";


interface Props {
  setDate: (value: string) => void;
  onClose: () => void;
}

const INITIAL_YEAR_COUNT = 1920;
const ScrollContainer = ({currentArray, currentIndex: propIndex, onSetCurrentIndex}: {
  currentArray: string[],
  currentIndex: number,
  onSetCurrentIndex: (value: number) => void,
}) => {
  const wrapperBoxRef = React.useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = React.useRef<NodeJS.Timeout>();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useLayoutEffect(() => {
    if (propIndex) {
      setCurrentIndex(propIndex);

      const container = wrapperBoxRef.current;
      if (container) {
        const height = propIndex * 30;
        container.scrollTo({top: height});
      }
    }
  }, [propIndex]);

  const handleScroll = () => {
    const container = wrapperBoxRef.current;

    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);

    scrollTimeoutRef.current = setTimeout(() => {
      const itemHeightWithMargin = 30;
      const halfContainer = 45;
      const halfItemWithMargin = 45;

      if (container) {
        const offset = (container.scrollTop + halfContainer - halfItemWithMargin);
        const closestToCenterIndex = Math.round(offset / itemHeightWithMargin);

        setCurrentIndex(closestToCenterIndex);
        onSetCurrentIndex(closestToCenterIndex);
        const top = closestToCenterIndex * itemHeightWithMargin;

        container.scrollTo({top, behavior: "smooth"});
      }
    }, 150);
  };


  React.useEffect(() => {
    return () => {
      clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  return <div className={styles.wrapperOverlay}>
    <div onScroll={handleScroll} ref={wrapperBoxRef} className={styles.item_wrapper}>
      {currentArray.map((item, index) => (
        <div
          key={index}
          className={[styles.item, index === currentIndex + 1 ? styles.centerItem : styles.adjacentItem].join(" ")}
        >
          <div>{item}</div>
        </div>
      ))}
    </div>
  </div>
}

export default function DatePicker({setDate, onClose}: Props) {
  const {t} = useTranslation();
  const [day, setDay] = React.useState(0);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState(generateMaxYear());

  const initialYear = generateYears(1920, generateMaxYear()).length - 3;
  const initialMonth = new Date().getMonth();
  const initialDay = new Date().getDate() - 1;

  const handleSubmit = () => {
    const currentDay = generateArray(getDaysInMonth(year + INITIAL_YEAR_COUNT, month)).find((_, index) => index === day + 1)?.padStart(2, "0") ?? "01";
    const currentMonth = generateArray(12).find((_, index) => index === month + 1)?.padStart(2, "0") ?? "01";
    const currentYear = generateYears(INITIAL_YEAR_COUNT, generateMaxYear()).find((_, index) => index === year + 1) ?? `${INITIAL_YEAR_COUNT}`;

    setDate(`${currentDay}.${currentMonth}.${currentYear}`);
    onClose();
  }

  return <div className={styles.main_wrapper}>
    <div className={styles.date_picker_wrapper}>
      <ScrollContainer
        currentArray={generateArray(getDaysInMonth(year + INITIAL_YEAR_COUNT, month))}
        currentIndex={initialDay}
        onSetCurrentIndex={value => setDay(value)}
      />

      <ScrollContainer
        currentArray={generateArray(12)}
        currentIndex={initialMonth}
        onSetCurrentIndex={value => setMonth(value)}
      />

      <ScrollContainer
        currentArray={generateYears(INITIAL_YEAR_COUNT, generateMaxYear())}
        currentIndex={initialYear}
        onSetCurrentIndex={value => {
          setYear(value);
        }}
      />
    </div>

    <Button onClick={handleSubmit} text={t("common.ready")} />
  </div>
}