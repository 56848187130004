import React, {useEffect}     from "react";
import {PageHeader, Toast}    from "shared/ui";
import cn                     from "classnames";
import styles                 from "./NewPageLayout.module.scss";
import {useScrollRestoration} from "use-scroll-restoration";


interface Props {
  step?: number;
  headerTitle?: string;
  headerSubtitle?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  headerLeftIcon?: React.ReactNode;
  headerRightIcon?: React.ReactNode;
  headerSecondRightIcon?: React.ReactNode;
  toastPosition?: number;
  isLoading?: boolean;
  pageHeaderChildren?: React.ReactNode;
  hasToast?: boolean;
}

function NewPageLayout({
  children,
  footer,
  headerTitle,
  headerSubtitle,
  step,
  headerLeftIcon,
  headerRightIcon,
  headerSecondRightIcon,
  toastPosition,
  isLoading = false,
  hasToast = true,
  pageHeaderChildren,
}: Props) {
  const {ref} = useScrollRestoration("pageLayout", {
    debounceTime: 200,
    persist:      "sessionStorage",
  });
  const [hasShadowHeader, setHasShadowHeader] = React.useState(false);
  const [hasShadowFooter, setHasShadowFooter] = React.useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;

    const hasHeaderShadow = target.scrollTop > 0;
    const hasFooterShadow = target.scrollTop + target.clientHeight < target.scrollHeight;

    setHasShadowHeader(hasHeaderShadow);
    setHasShadowFooter(hasFooterShadow);
  }

  useEffect(() => {
    const content = document.getElementById("scrollable_body_content");

    if (content) {
      const updateShadows = () => {
        setHasShadowHeader(content.scrollTop > 0);
        setHasShadowFooter(content.scrollHeight > content.clientHeight && content.scrollTop + content.clientHeight < content.scrollHeight);
      };

      const observer = new ResizeObserver(updateShadows);
      observer.observe(content);

      content.addEventListener("scroll", updateShadows);

      updateShadows();

      return () => {
        observer.disconnect();
        content.removeEventListener("scroll", updateShadows);
      };
    }
  }, []);

  return <div className={styles.wrapper}>
    <PageHeader
      isLoading={isLoading}
      hasShadow={hasShadowHeader}
      step={step}
      title={headerTitle}
      leftIcon={headerLeftIcon}
      subtitle={headerSubtitle}
      secondRightIcon={headerSecondRightIcon}
      rightIcon={headerRightIcon}
    >
      {pageHeaderChildren && pageHeaderChildren}
    </PageHeader>

    <div
      ref={ref}
      id="scrollable_body_content"
      className={cn(styles.content, {[styles.has_step]: !!step}, {[styles.header_children]: !!pageHeaderChildren})}
      onScroll={handleScroll}
    >
      {children}
    </div>

    {!!footer && <div className={cn(styles.footer, {[styles.has_shadow]: hasShadowFooter})}>
      {footer}
    </div>}

    {hasToast && <Toast bottomPosition={toastPosition} />}
  </div>
}

export default React.memo(NewPageLayout);