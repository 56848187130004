import React                                                  from "react";
import {useTranslation}                                       from "react-i18next";
import {
  Outlet,
  useNavigate,
}                                                             from "react-router-dom";
import {Button, Icon, NewButtonColor, NewButtonType}          from "shared/v12ui";
import {InApp}                                                from "shared/ui";
import {
  NativeActions,
  Nullable,
  selectAppPlatform,
  selectTicketIsDuplicated,
  setApiSession,
  useAppDispatch,
  useAppSelector,
}                                                             from "shared/model";
import {setActionForNative, useNativeHandler, useToggleState} from "shared/lib";
import {
  selectDescriptionError,
  selectFirstStepData, selectOpenMediaTransfer,
  selectSecondStepData,
  selectStepCount,
  selectThirdStepData,
  setPhoneError,
  setPriceError,
  updateStepCount,
}                                                             from "./model/slice/orderSlice";
import {
  useFetchCloseOrderInfoMutation,
  useFetchOrderRecommendationMutation,
  useSaveOrderMutation,
}                                                             from "./api/orderDetailsApi";
import {NewPageLayout}                                        from "../general";
import {useSetDataFromRequest}                                from "./model";
import DescriptionInApp                                       from "./ui/discriptionInApp/DescriptionInApp";
import SaveOrderInApp                                         from "./ui/saveOrderInApp/SaveOrderInApp";
import {setErrorMessage}                                      from "../../pages/registration/model/registration";
import styles                                                 from "./OrderPageLayout.module.scss";


export default function OrderPageLayout() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectStepCount);
  const isDuplicatedTicket = useAppSelector(selectTicketIsDuplicated);
  const {description, dateTab, isFast, media, date, dateAndTime} = useAppSelector(selectFirstStepData);
  const {price, isAppPaymentWay, price_limit} = useAppSelector(selectSecondStepData);
  const {isAllowed, phone, addresses} = useAppSelector(selectThirdStepData);
  const descriptionError = useAppSelector(selectDescriptionError);
  const platform = useAppSelector(selectAppPlatform);
  const transferMediaIsOpen = useAppSelector(selectOpenMediaTransfer);

  const [ticketBonus, setTicketBonus] = React.useState<Nullable<number>>(null);

  const {ticketId, isDemo, workId} = useSetDataFromRequest({setTicketBonus});
  const [getRecommendation, {
    data:      recommendations,
    isLoading: recommendationsIsLoading,
    reset:     resetRecommendations,
  }] = useFetchOrderRecommendationMutation();
  const [createOrder, {data: createOrderResponse, isLoading: createOrderIsLoading}] = useSaveOrderMutation();
  const [closeOrder] = useFetchCloseOrderInfoMutation();

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [closeInApp, toggleCloseInApp] = useToggleState();

  const handleGetRecommendation = () => {
    if (!isDuplicatedTicket) {
      return getRecommendation({
        description: description,
        ticket_id:   ticketId ?? undefined,
        work_id:     workId ?? undefined,
      });
    } else {
      return getRecommendation({
        description: description,
        ticket_id:   ticketId ?? undefined,
        work_id:     workId ?? undefined,
      });
    }
  }

  const handleMoveToStepThreeClick = () => {
    if (price && price_limit) {
      if (price < price_limit.min) {
        dispatch(setPriceError(t("page.order.price_limit.min")));
        return;
      }
      if (price > price_limit.max) {
        dispatch(setPriceError(t("page.order.price_limit.max", {sum: price_limit.max})));
        return;
      }
    }
    navigate("/order/third-step");
    dispatch(updateStepCount(step + 1));
  }

  const handleIncreaseStepClick = () => {
    if (step === 0) {
      handleGetRecommendation();
    }
    if (step === 1) {
      handleMoveToStepThreeClick();
    }
    if (step === 2) {
      handleSubmitOrder();
    }
  }

  const handleDecreaseStepClick = () => {
    if (step === 1) {
      navigate("");
      dispatch(updateStepCount(step - 1));
    }
    if (step === 2) {
      navigate("/order/second-step");
      dispatch(updateStepCount(step - 1));
    }
  }

  const createOrderData = () => {
    const currentAddresses = addresses.map((address) => ({
      address:   address.name,
      longitude: address.coord[0],
      latitude:  address.coord[1],
      city_id:   address.city_id,
    }));

    const getTicketDate = () => {
      if (dateTab !== "fast" && dateTab !== "selectDate") {
        return date;
      }
      if (dateTab === "selectDate") {
        return dateAndTime?.date;
      }
      return undefined;
    }
    const currentMedia = media?.filter((item) => item.digest !== undefined)
                              .map((item) => ({type: item.type, digest: item.digest as string}));

    const getCurrentTime = () => {
      if (!dateAndTime?.time) return undefined;
      if (dateAndTime.date === getTicketDate()) {
        return dateAndTime.time.replace(/\s/g, "")
      }
    }

    const currentTicketId = isDuplicatedTicket ? undefined : ticketId;

    return createOrder({
      ticket_id:          currentTicketId ?? undefined,
      work_id:            workId ? workId : undefined,
      is_allowed:         isAllowed,
      description:        description,
      addresses:          currentAddresses ?? [],
      work_unit_id:       null,
      price_to:           price,
      is_fast:            isFast,
      is_app_payment_way: isAppPaymentWay ?? false,
      date:               getTicketDate(),
      media:              currentMedia,
      time:               getCurrentTime(),
    });
  }

  const handleSubmitOrder = () => {
    if (isDemo && isAllowed && !isLoggedIn) {
      if (!phone) {
        dispatch(setPhoneError(t("page.order.phone_error")))
        return;
      }
      const currentPhone = phone?.replace(/\D/g, "");
      return setActionForNative(NativeActions.LOGIN, {phone: currentPhone});
    }

    return createOrderData();
  }

  const handleLogin = (data: { session: Nullable<string>, is_logged_in: boolean }) => {
    setIsLoggedIn(data.is_logged_in);
    if (data.is_logged_in) {
      if (data.session) {
        dispatch(setApiSession(data.session));
        createOrderData();
      }
    }
  }

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleCloseOrderButtonClick = () => {
    if (description && description?.trim() !== "" && !ticketId) {
      if (ticketBonus) {
        closeOrder();
      }
      return toggleCloseInApp();
    } else {
      return handleOnCloseClick();
    }
  }

  const descriptionWithBonus = ticketBonus
    ? <div className={styles.description}>
      {t("page.order.close_order.subtext_with_bonus.first_part")}
      <span className={styles.sum}>
        {t("page.order.close_order.subtext_with_bonus.second_part", {bonus: ticketBonus})}
      </span>
      {t("page.order.close_order.subtext_with_bonus.third_part")}
    </div>
    : t("page.order.close_order.subtext");

  const thirdStepButtonText = () => {
    if (step === 2) {
      if (!isDuplicatedTicket && ticketId) {
        return t("common.save")
      }
      return t("page.order.create_order_button");
    }
    return t("common.next");
  }

  useNativeHandler<{ session: Nullable<string>, is_logged_in: boolean }>(null, NativeActions.LOGIN, (data) => {
    return handleLogin(data);
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (transferMediaIsOpen) {
      return;
    }
    if (closeInApp) {
      return toggleCloseInApp();
    }
    if (step === 0) {
      if (platform === "android") {
        return handleCloseOrderButtonClick()
      }
    }
  });

  React.useEffect(() => {
    if (createOrderResponse?.message) {
      dispatch(setErrorMessage(createOrderResponse.message));
    }
  }, [createOrderResponse?.message]);

  return <>
    <NewPageLayout
      hasToast={false}
      headerRightIcon={<Icon onClick={handleCloseOrderButtonClick} iconColor={"#2B2B2B"}>close</Icon>}
      headerTitle={t("page.order.header.title")}
      headerSubtitle={t(`page.order.header.subtitle.${step}`)}
      step={step + 1}
      footer={<Button
        type="submit"
        additionalIcon="keyboard_arrow_left"
        text={thirdStepButtonText()}
        onClick={handleIncreaseStepClick}
        onAdditionalButtonClick={handleDecreaseStepClick}
        buttonColor={step === 2 ? NewButtonColor.SUCCESS : NewButtonColor.BLACK}
        loading={step === 2 ? createOrderIsLoading : recommendationsIsLoading}
        disabled={!!descriptionError}
      >
        {step > 0 && <Button.AdditionalButton />}
      </Button>
      }
    >
      <Outlet />
    </NewPageLayout>

    <DescriptionInApp
      isEditOrder={!!ticketId}
      recommendations={recommendations}
      resetRecommendation={resetRecommendations}
    />

    {createOrderResponse?.content?.alert && <SaveOrderInApp alert={createOrderResponse.content?.alert} />}

    <InApp
      image="/illustrations/delete-4.svg"
      headerText={t("page.order.close_order.title")}
      headerSubtext={descriptionWithBonus}
      open={closeInApp}
      onClose={toggleCloseInApp}
    >
      <div className="inApp_buttons">
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          text={t("common.exit")}
          onClick={handleOnCloseClick}
        />
        <Button
          text={t("common.continue")}
          onClick={toggleCloseInApp}
          hasSpace
        />
      </div>
    </InApp>
  </>
};