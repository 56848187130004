import React, {ChangeEventHandler, forwardRef, useId} from "react";
import cn                                             from "classnames";
import styles                                         from "./styles.module.scss";


interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Toggle = forwardRef<HTMLInputElement, Props>(({checked, disabled, onChange}: Props, ref) => {
  const id = useId();

  return <div className={styles.holder}>
    <label htmlFor={id} className={cn(styles.wrapper, {[styles.active]: checked}, {[styles.disabled]: disabled})}>
      <div className={cn(styles.indicator, {[styles.active]: checked})} />
    </label>
    <input
      ref={ref}
      id={id}
      checked={checked}
      disabled={disabled}
      type="checkbox"
      className={cn(styles.wrapper, {[styles.active]: checked}, {[styles.disabled]: disabled})}
      onChange={onChange}
      style={{opacity: 0}}
    />
  </div>
})

export default Toggle;